mat-icon {
  &.size-xs {
    width: 0.7rem;
    height: 0.7rem;
  }

  &.size-sm {
    width: 1rem;
    height: 1rem;
  }

  &.size-smd {
    width: 1.25rem;
    height: 1.25rem;
  }

  &.size-md {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.size-lg {
    width: 2rem;
    height: 2rem;
  }
}
