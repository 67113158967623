@use "ag-grid-community/styles" as ag;

@include ag.grid-styles((
  theme: aps-grid,
  extend-theme: alpine,
  border-radius: 8px,
  header-background-color: var(--color-super-light-gray),
  odd-row-background-color: transparent,
  row-hover-color: var(--color-super-light-gray),
  header-height: 32px,
));

.ag-grid-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);

  .ag-grid-loader {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: 4px;
  }
}

.ag-theme-aps-grid {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  --ag-borders: none;
  --ag-font-family: Outfit, "Helvetica Neue", sans-serif;

  .ag-row {
    border-bottom: 6px solid white;
    border-top: 6px solid white;
  }

  .ag-cell-value {
    display: flex;
    align-items: center;
  }

  &.with-pagination {
    height: calc(100% - var(--mat-paginator-height));
  }

  .ag-header-row {
    font-weight: 500;
  }
}

.left-border {
  border-left: 4px solid;
  border-right: none !important;
  border-image: linear-gradient(to right, var(--color-medium-tint), var(--color-white)) 1 100%;
}

.cell-overflow-ellipsis {
  display: flex !important;
  min-width: 0;
}

aps-ag-cell-string,
aps-ag-cell-user-list,
aps-ag-cell-distance,
aps-ag-cell-status,
aps-ag-cell-elements-counter,
aps-ag-cell-number,
aps-ag-cell-oc-number,
aps-ag-cell-project-name,
aps-ag-cell-tools,
aps-ag-cell-username,
aps-ag-cell-poi {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}
