:root {
  --tab-bar-height: 64px;
  /** New brand colors **/
  --color-grey: #f9f9f8;
  --color-light-gray: #e3e3e3;
  --color-midgray: #c5c6c6;
  --color-dark-gray: #878786;
  --color-super-light-gray: #EFEFEE;
  --color-laserred: #e40033;
  --color-orange: #e65917;
  --color-dark-green: #0b733f;
  --color-white: #ffffff;

  /** primary **/
  --color-primary: var(--color-laserred);

  /** secondary **/
  --color-secondary: #3dc2ff;

  /** tertiary **/
  --color-tertiary: #5260ff;
  --color-tertiary-rgb: 82, 96, 255;

  /** success **/
  --color-success: #2dd36f;
  --color-success-rgb: 45, 211, 111;

  /** warning **/
  --color-warning: #ffc409;
  --color-warning-rgb: 255, 196, 9;

  /** danger **/
  --color-danger: #eb445a;
  --color-danger-rgb: 235, 68, 90;

  /** dark **/
  --color-dark: #222428;

  /** medium **/
  --color-medium: #92949c;
  --color-medium-rgb: 146, 148, 156;
  --color-medium-tint: #9d9fa6;

  /** light **/
  --color-light: var(--color-light-gray);

  --mat-sidenav-content-background-color: var(--color-grey);
  --mat-sidenav-container-divider-color: transparent;
  --mdc-dialog-container-shape: 8px;
}
