$scrollbar-size: 0.6rem;
$scrollbar-color: var(--color-medium);

::-webkit-scrollbar {
  width: $scrollbar-size;
  height: $scrollbar-size;
}

::-webkit-scrollbar-thumb {
  border-radius: calc(0.6rem / 2);
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: $scrollbar-color;
}

