/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import './theme';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Outfit, "Helvetica Neue", sans-serif;
}
