.color-primary {
  color: var(--color-primary) !important;
}

.color-secondary {
  color: var(--color-secondary) !important;
}

.color-tertiary {
  color: var(--color-tertiary) !important;
}

.color-success {
  color: var(--color-success) !important;
}

.color-warning {
  color: var(--color-warning) !important;
}

.color-danger {
  color: var(--color-danger) !important;
}

.color-dark {
  color: var(--color-dark) !important;
}

.color-medium {
  color: var(--color-medium) !important;
}

.color-light {
  color: var(--color-light) !important;
}

.bg-primary {
  background-color: var(--color-primary) !important;
}

.bg-secondary {
  background-color: var(--color-secondary) !important;
}

.bg-tertiary {
  background-color: var(--color-tertiary) !important;
}

.bg-success {
  background-color: var(--color-success) !important;
}

.bg-warning {
  background-color: var(--color-warning) !important;
}

.bg-warning-tint {
  background-color: var(--color-warning-tint) !important;
}

.bg-danger {
  background-color: var(--color-danger) !important;
}

.bg-dark {
  background-color: var(--color-dark) !important;
}

.bg-medium {
  background-color: var(--color-medium) !important;
}

.bg-light {
  background-color: var(--color-light) !important;
}

.bg-super-light-gray {
  background-color: var(--color-super-light-gray) !important;
}

.bg-white {
  background-color: var(--color-white) !important;
}
