mat-dialog-container {
  .mdc-dialog {
    &__title {
      display: flex;
      padding: 24px;

      &::before {
        height: 0;
      }
    }

    &__content {
      max-height: 70vh;
    }

    &__actions {
      padding: 24px;
    }
  }

  .mdc-dialog-close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.dialog-section-title {
  @apply text-sm font-semibold;
}
