:root {
  --mat-paginator-height: 36px;
}

mat-paginator {
  border-radius: 8px;
  .mat-mdc-paginator-container {
    min-height: 36px;
  }

  .mat-mdc-paginator-page-size {
    .mat-mdc-form-field-flex > .mat-mdc-form-field-infix {
      padding: 0 !important;
      min-height: 20px;
    }
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 36px;
    height: 36px;
    padding: 0;
  }
}
