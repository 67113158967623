@use '@angular/material' as mat;

mat-slide-toggle {
  --mdc-switch-handle-width: 20px;
  --mdc-switch-track-height: 20px;
  --mdc-switch-track-width: 40px;
  --mdc-switch-track-shape: 20px;
  --mat-switch-with-icon-handle-size: 17px !important;

  --mdc-switch-unselected-track-color: var(--color-super-light-gray);
  --mdc-switch-unselected-handle-color: var(--color-dark-gray);

  --mdc-switch-selected-track-color: var(--color-primary);
  --mdc-switch-selected-focus-handle-color: var(--color-super-light-gray);
  --mdc-switch-selected-hover-handle-color: var(--color-super-light-gray);
  --mdc-switch-selected-handle-color: var(--color-super-light-gray);

  transition: background-color 0.3s ease, color 0.3s ease;

  .mdc-switch__icons {
    display: none;
  }
}
