.mdc-notched-outline__notch {
  border-right: none !important;
}

.mat-mdc-option {
  input,
  textarea,
  span {
    line-height: 1.43;
    font-size: 14px;
  }
}

.mat-mdc-form-field, .mat-mdc-select, .mat-mdc-option {
  font-size: 14px;
  line-height: 23px;

  mat-icon {
    width: 16px;
    height: 16px;
  }
}

.mat-mdc-form-field-flex > .mat-mdc-form-field-infix {
  padding: 0.6em 0 !important;
  min-height: 40px;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 16px;
}

.mat-mdc-text-field-wrapper {
  background: white;
}

.mat-form-field-disabled {
  pointer-events: none;
}

.project-filter-form-field .mat-mdc-text-field-wrapper {
  background: var(--color-super-light-gray);
  --mdc-outlined-text-field-input-text-color: var(--color-dark-gray);
  --mdc-outlined-text-field-outline-color: var(--color-light-gray);
  --mdc-outlined-text-field-container-shape: 8px;
}

label {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;

  &.required::after {
    margin-left: 1px;
    margin-right: 0;
    content: "*";
    color: var(--color-danger);
  }
}
