.aps-divider-shadow::after {
  content: '';
  width: 8px;
  height: 100%;
  position: absolute;
  left: 0;
  margin: auto;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(78, 87, 94, 0.2) 100%);
}
